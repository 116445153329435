<template>
  <div class="subpage-dashboards subpage-usage--dashboard">
    <banner-dual-action
      class="gds-flex-container"
      v-if="testDriveUsage"
      bannerColor="blue"
      message="You are trying out our new usage section and we’d love to hear what you think. Click this link to provide feedback: "
      linkOneText="Provide Feedback."
      @linkOneClicked="showProvideFeedBackModal"
      messageTwo="Or you can use this link to: "
      linkTwoText="Switch Back to the Previous Usage View"
      @linkTwoClicked="goToLegacyUsage"
    />
    <h1 class="my-account__title gds-space-stack-default">Usage</h1>
    <p class="usage-dashboard___sub-title gds-space-stack-l">
      How clean electricity is powering your life.
    </p>
    <div v-if="loadState === undefined || loadState === 'loading'" class="table-loading" />
    <div v-if="loadState !== 'complete'" class="usage-page--interior-status-container">
      <flow-error v-if="loadState === 'error'" name="Usage information" state="error" />
      <flow-error v-if="loadState === 'maintenance'" name="Usage information" state="maintenance" />
      <flow-error v-if="loadState === 'unavailable'" name="Usage information" state="unavailable" img="/wp-content/themes/gmptwentynineteen/assets/images/usage-not-supported.svg" />
    </div>

    <div v-if="loadState === 'complete'">

    <!-- WEB-084 - FOR ROLLBACK, SWAP THE TWO SECTIONS BELOW -->

    <!-- START WEB-084 - new usage navigation -->
    <div class="usage-dashboard__jump-nav">
      <nav class="usage-detail__router-link-container">
        <a href="/account/usage" class="usage-detail__nav-item router-link-active">
          <div class="usage-detail__nav-img">
            <div class="gds-icon gds-flex-container">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 0H0V6H6V0ZM6 9H0V15H6V9ZM9 0H15V6H9V0ZM15 9H9V15H15V9Z" fill="#00704A" />
              </svg>
            </div>
            <span class="usage-detail__nav-item__text">Dashboard</span>
          </div>
        </a>
        <a href="/account/usage/detail/graph" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-graph.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-graph-green.svg" />
            <span class="usage-detail__nav-item__text">Graph</span>
          </div>
        </a>
        <a href="/account/usage/detail/table" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-table.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-table-green.svg" />
            <span class="usage-detail__nav-item__text">Table</span>
          </div>
        </a>
        <a v-if="!isNetMetered" href="/account/usage/detail/time-of-day" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-time-of-day.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-time-of-day-green.svg" />
            <span class="usage-detail__nav-item__text">Time Of Day</span>
          </div>
        </a>
        <a v-if="FF_usageEvCharging && currentAccount && currentAccount.evCharger" href="/account/usage/detail/ev-charging" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-ev-icon.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-ev-icon-green.svg" />
            <span class="usage-detail__nav-item__text ev-charging">EV Charging</span>
          </div>
        </a>
        <!-- <a href="detail/seasonal" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-seasonal.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-seasonal-green.svg" />
            <span class="usage-detail__nav-item__text">Seasonal</span>
          </div>
        </a> -->
        <!--
        <a href="detail/heating-cooling" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img
              src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-heating-cooling.svg" />
            Heating/Cooling
          </div>
        -->
        <!-- </a> -->
        <a href="/account/usage/detail/performance" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-performance.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-performance-green.svg" />
            <span class="usage-detail__nav-item__text">Performance</span>
          </div>
        </a>
        <a href="/account/usage/detail/compare" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-compare.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-compare-green.svg" />
            <span class="usage-detail__nav-item__text">Compare</span>
          </div>
        </a>
      </nav>
    </div>
    <!-- END WEB-084 - new usage navigation -->

    <!-- START WEB-084 - old usage-new navigation -->
    <!-- <div class="usage-dashboard__jump-nav">
      <nav class="usage-detail__router-link-container">
        <a href="/account/usage-new" class="usage-detail__nav-item router-link-active">
          <div class="usage-detail__nav-img">
            <div class="gds-icon gds-flex-container">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M6 0H0V6H6V0ZM6 9H0V15H6V9ZM9 0H15V6H9V0ZM15 9H9V15H15V9Z" fill="#00704A" />
              </svg>
            </div>
            <span class="usage-detail__nav-item__text">Dashboard</span>
          </div>
        </a>
        <a href="/account/usage-new/detail/graph" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-graph.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-graph-green.svg" />
            <span class="usage-detail__nav-item__text">Graph</span>
          </div>
        </a>
        <a href="/account/usage-new/detail/table" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-table.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-table-green.svg" />
            <span class="usage-detail__nav-item__text">Table</span>
          </div>
        </a>
        <a v-if="!isNetMetered" href="/account/usage-new/detail/time-of-day" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-time-of-day.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-time-of-day-green.svg" />
            <span class="usage-detail__nav-item__text">Time Of Day</span>
          </div>
        </a>
        <a v-if="FF_usageEvCharging && currentAccount && currentAccount.evCharger" href="/account/usage-new/detail/ev-charging" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-ev-icon.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-ev-icon-green.svg" />
            <span class="usage-detail__nav-item__text ev-charging">EV Charging</span>
          </div>
        </a>
        <a href="detail/seasonal" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-seasonal.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-seasonal-green.svg" />
            <span class="usage-detail__nav-item__text">Seasonal</span>
          </div>
        </a>
        <a href="detail/heating-cooling" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img
              src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-heating-cooling.svg" />
            Heating/Cooling
          </div>
        </a>
        <a href="/account/usage-new/detail/performance" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-performance.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-performance-green.svg" />
            <span class="usage-detail__nav-item__text">Performance</span>
          </div>
        </a>
        <a href="/account/usage-new/detail/compare" class="usage-detail__nav-item">
          <div class="usage-detail__nav-img">
            <img class="non-green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-compare.svg" />
            <img class="green-svg" src="/wp-content/themes/gmptwentynineteen/assets/images/usage-dashboard/usage-visual-compare-green.svg" />
            <span class="usage-detail__nav-item__text">Compare</span>
          </div>
        </a>
      </nav>
    </div> -->
    <!-- END WEB-084 - old usage-new navigation -->
    <!-- start of grid -->
    <div
      class="usage-dashboard-grid"
      :class="{ 'is-net-metered': isNetMetered }"
    >
      <!-- start of widget graph-layout-with-total-home-consumption-disabled-->
      <div class="usage-dashboard__widget usage-widget__graph">
        <div
          v-if="
            bothFeatureFlagAndIsNetmeteredConditionsChecked === 2 &&
              (!FF_NetMeteredDashboard ||
                (FF_NetMeteredDashboard && !isNetMetered))
          "
        >
          <widget-monthly-consumption />
        </div>
        <div
          v-else
          class="usage-dashboard-widget-usage-trend-wrapper usage-dashboard-grid-short--height-auto"
        >
          <widget-monthly-consumption :isNetMetered="isNetMetered" />
        </div>
      </div>
      <!-- end of widget -->

      <!-- start of widget table-->
      <div class="usage-dashboard__widget usage-widget__table">
        <widget-daily-consumption />
      </div>
      <!-- end of widget -->

      <!-- start of widget net-metering-diagram-->
      <div
        v-if="isNetMetered"
        class="usage-dashboard__widget usage-widget__net-metering-diagram"
        id="usage-dashboard__widget-net-metering-container"
      >
        <widget-net-metering
          :hasNarrowContainer="widgetNetMeteringHasNarrowContainer"
          :loadState="loadState"
          :billingSummary="billingSummary"
          :isDashboardView="isDashboardView"
          :showDetailArrow="showDetailArrow"
          :summaryGeneration="summaryGeneration"
          :summaryTextGeneration="summaryTextGenerationForNetMeteringWidget"
          :summaryIconTotalGeneration="
            summaryIconTotalConsumptionForNetMeteringWidget
          "
          v-if="
            bothFeatureFlagAndIsNetmeteredConditionsChecked === 2 &&
              FF_NetMeteredDashboard && isNetMetered
          "
          @change-options-event="handleOptionChangeEvent"
        />
      </div>
      <!-- end of widget -->

      <!-- start of widget performance-graph -->
      <div
        v-if="!isNetMetered"
        class="usage-dashboard__widget usage-widget__performance-graph"
      >
        <widget-current-usage
          v-if="
            bothFeatureFlagAndIsNetmeteredConditionsChecked === 2 &&
              (!FF_NetMeteredDashboard ||
                (FF_NetMeteredDashboard && !isNetMetered))
          "
          @change-options-event="handleOptionChangeEvent"
        />
      </div>

      <!-- end of widget -->

      <!-- start of widget -->
      <div
        v-if="!isNetMetered"
        class="usage-dashboard__widget usage-widget__time-of-day usage-dashboard-widget-time-of-day-wrapper"
      >
        <widget-time-of-day />
      </div>
      <!-- end of widget -->

    </div>
    <!-- end of grid -->

    <div class="gds-space-stack-l">
      <UsageVisualizationAndTools :isNonNetMeteredAccount="!isNetMetered" />
    </div>
    <div class="gds-space-stack-l">
      <usage-notification />
    </div>
    <div class="gds-space-stack-l">
      <usage-export />
    </div>
    
    <modal-floating-feedback
      :visible="showFeedback"
      @close="showFeedback = false"
    />
    </div>
</div>
</template>

<script>
import UsageExport from "./usageexport/UsageExport.vue";
import MediaQueryMixin from "../../mixins/MediaQueryMixin";
import WidgetTimeOfDay from "./widgettimeofday/WidgetTimeOfDay.vue";
import WidgetUsageTrend from "./widgetusagetrend/WidgetUsageTrend.vue";
import UsageNotification from "./usagenotification/UsageNotification.vue";
import UsageVisualizationAndTools from "./UsageVisualizationAndTools.vue";
import WidgetCurrentUsage from "./widgetcurrentusage/WidgetCurrentUsage.vue";
import WidgetNetMetering from "./widgetnetmetering/WidgetNetMetering.vue";
import WidgetDailyConsumption from "./widgetdailyconsumption/WidgetDailyConsumption.vue";
import BannerDualAction from "../../BannerDualAction.vue";
import ModalFloatingFeedback from "../../ModalFloatingFeedback.vue";
import GMPAPI from "../../../services/gmpapi";
import { DumpError } from "../../../utilities";
import { isFeatureEnabled } from "../../../services/featureflags";

import WidgetMonthlyConsumption from "./widgetmonthlyconsumption/WidgetMonthlyConsumption.vue";

const RESIZE_EVENT = "resize";
const NARROW_PARENT_MAX_WIDTH = 720;

export default {
  name: "SubpageUsage",
  components: {
    UsageExport,
    WidgetTimeOfDay,
    WidgetUsageTrend,
    UsageNotification,
    WidgetMonthlyConsumption,
    WidgetCurrentUsage,
    WidgetNetMetering,
    WidgetDailyConsumption,
    UsageVisualizationAndTools,
    BannerDualAction,
    ModalFloatingFeedback,
  },
  mixins: [MediaQueryMixin],
  data() {
    return {
      widgetNetMeteringHasNarrowContainer: false,
      isDashboardView: true,
      showDetailArrow: true,
      pickAViewModalVisible: false,
      showFeedback: false,
      isNetMetered: undefined,
      FF_usageEvCharging: undefined,
      FF_NetMeteredDashboard: undefined,
      bothFeatureFlagAndIsNetmeteredConditionsChecked: 0,
      billingSummary: undefined,
      loadState: undefined,
      summaryGeneration: undefined,
      summaryTextGeneration: undefined,
      summaryIconTotalGeneration: undefined,
    };
  },
  computed: {
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    testDriveUsage() {
      // WEB-084 - for rollback, swap commenting below
      return false;
      // return this.$store.state.user.testDriveUsage;
    },
    isMobbileView() {
      return this.deviceIsSmall;
    },
    isTabletView() {
      return this.deviceIsMedium || this.deviceIsLarge;
    },
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
  },
  watch: {
    async currentAccount() {
      this.bothFeatureFlagAndIsNetmeteredConditionsChecked = 1;
      await this.getBillingSummary();
      setTimeout(() => this.onResize(), 800);
    },
  },
  async mounted() {
    await this.getConfigs();
    await this.getBillingSummary();
    setTimeout(() => this.onResize(), 800);
    window.addEventListener(RESIZE_EVENT, this.onResize);
    this.FF_usageEvCharging = await isFeatureEnabled('FF_UsageEVCharging', false);
  },
  beforeDestroy() {
    window.removeEventListener(RESIZE_EVENT, this.onResize);
  },
  methods: {
    onResize() {
      this.widgetNetMeteringHasNarrowContainer = this.parentElementisNarrow();
    },
    async getConfigs() {
      this.FF_NetMeteredDashboard = await isFeatureEnabled(
        "FF_NetMeteredDashboard",
        false
      );
      this.bothFeatureFlagAndIsNetmeteredConditionsChecked++;
    },
    showPickAViewModal() {
      this.pickAViewModalVisible = true;
    },
    hidePickAViewModal() {
      this.pickAViewModalVisible = false;
    },
    async goToLegacyUsage() {
      this.$store.commit("setTestDriveUsage", false);
      window.location.href = "/account/usage";

      await GMPAPI.SubmitCSSLogging(
        this.currentAccount.accountNumber,
        this.$store.state.user.userinfo.username,
        "SWITCH_TO_LEGACY_USAGE",
        "Switched back to legacy usage.",
        true
      );
    },
    showProvideFeedBackModal() {
      this.showFeedback = true;
    },
    parentElementisNarrow() {
      const element = document.getElementById(
        "usage-dashboard__widget-net-metering-container"
      );
      return element ? element.scrollWidth < NARROW_PARENT_MAX_WIDTH : false;
    },
    buildNetMeteringWidgetSummaryText(billingSummary) {
      const netGeneration =
        billingSummary.totalGrossGenerated - billingSummary.totalConsumption;
      const netGenerationAbs = Math.abs(netGeneration);
      const formattedNetGeneration = netGenerationAbs.toLocaleString('en-US', {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      });
      const netGenerationIsNegative = netGeneration < 0;
      const isCurrentBillingPeriod =
        this.currentBillingCycleDates && this.currentBillingCycleDates.current;

      if (netGenerationIsNegative) {
        this.summaryGeneration = `${formattedNetGeneration} kWh Net Use`;
        this.summaryIconTotalConsumptionForNetMeteringWidget =
          "usage-icon-performance-arrow-down.svg";
        this.summaryTextGenerationForNetMeteringWidget = `So far, you generated ${formattedNetGeneration} kWh less than consumed.`;
      } else {
        this.summaryGeneration = `${formattedNetGeneration} kWh Credit`;
        this.summaryIconTotalConsumptionForNetMeteringWidget =
          "usage-icon-performance-plus.svg";
        this.summaryTextGenerationForNetMeteringWidget = `So far, you generated ${formattedNetGeneration} kWh more than consumed.`;
      }
    },
    async getBillingSummary() {
      try {
        this.loadState = "loading";
        let summary;

        try {
          summary = await GMPAPI.GetUsageSummary(this.currentAccount.accountNumber);
        } catch (err) {
          /* If summary record not found and account is not net metered, return empty object
             we can allow component to render as only net metered accounts need summary data */
          if (err?.response?.status === 404 && !this.currentAccount.netMetered) {
            summary = {};
          } else {
            throw err;
          }
        }
        this.billingSummary = summary;
        this.isNetMetered = summary.isNetMetered ?? false;
        this.buildNetMeteringWidgetSummaryText(this.billingSummary);
        this.loadState = "complete";

      } catch (err) {
        this.loadState = "error";
        this.isNetMetered = false;
        DumpError("Get Billing Summary error", err);
      } finally {
        this.bothFeatureFlagAndIsNetmeteredConditionsChecked++;
      }
    },
    handleOptionChangeEvent(option) {
      if (option === "widget-net-metering") {
        this.isNetMetered = true;
        return;
      }
      this.isNetMetered = false;
    },
  },
};
</script>
